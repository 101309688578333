import React from "react"
import styled from "@emotion/styled"
import { color, ColorProps } from "styled-system"

const VideoBlock = styled.video<ColorProps & { color?: string }>`
  display: block;
  width: 100%;
  object-fit: cover;
  object-position: 50% 50%;
  -webkit-border-radius: 1px;
  height: 100vh;
  background-color: #111;

  ${color};
`

type VideoSource = {
  src: string
  type: "video/mp4" | "video/webm"
}

type VideoProps = ColorProps & { color?: string } & React.HTMLAttributes<
    HTMLVideoElement
  > & {
    sources: VideoSource[]
    loop?: boolean
    fallbackImageSrc?: string
  }

const Video = ({
  sources,
  loop = true,
  fallbackImageSrc,
  ...props
}: VideoProps) => {
  const [autoplayNotAllowed, setAutoPlayNotAllowed] = React.useState(false)
  const ref = React.useCallback(element => {
    if (!element) {
      return
    }

    // automatically play the video on mount
    element?.play()?.catch((err: Error) => {
      if (err.name === "NotAllowedError") {
        console.log("Autoplay not allowed!")
        setAutoPlayNotAllowed(true)
      } else {
        console.error(err, "Unable to play video!")
      }
    })
  }, [])

  if (autoplayNotAllowed && fallbackImageSrc) {
    return <VideoBlock as="img" src={fallbackImageSrc} />
  }

  return (
    <VideoBlock ref={ref} {...props} muted loop={loop} playsInline>
      {sources.map(({ src, type }) => (
        <source key={src} src={src} type={type} />
      ))}
    </VideoBlock>
  )
}

export default Video
