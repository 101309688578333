import styled from "@emotion/styled"
import Img, {
  GatsbyImageWithIEPolyfillProps,
} from "gatsby-image/withIEPolyfill"
import React, { useEffect, useRef, useState } from "react"
import { useInView } from "react-intersection-observer"
import { color, ColorProps } from "styled-system"
import { interpolate } from "@popmotion/popcorn"

const Container = styled.section<Omit<ColorProps, "color">>`
  position: relative;
  z-index: 0;
  min-height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 32px;
  overflow: hidden;
  background-color: #111;
  color: #fff;
  ${color}
`

const BackgroundImage = styled(Img)`
  height: 100vh !important;
`

const ParallaxBackground = styled.div`
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  object-fit: cover;
  height: 100%;
  will-change: transform;

  &[data-in-view="true"] {
    position: fixed;
  }
`

export type ParallaxProps = {
  backgroundProps?: GatsbyImageWithIEPolyfillProps
  children?: React.ReactNode
  threshold?: number
  color?: string
} & ColorProps &
  React.PropsWithoutRef<JSX.IntrinsicElements["section"]>

const ParallaxOpacity = ({
  backgroundProps = {},
  threshold = 1,
  children,
  ...props
}: ParallaxProps) => {
  const frame = useRef(0)
  const bg = useRef<HTMLDivElement | null>(null)
  const [viewportHeight, setViewportHeight] = useState<number>()
  const [percent, setPercent] = useState<number>(0)
  const [topSentinel, setTopSentinel] = useState<boolean>(false)
  const [bottomSentinel, setBottomSentinel] = useState<boolean>(false)
  const [ref, inView, entry] = useInView({
    rootMargin: "0% 0% 0% 0%",
  })
  const entryTarget = entry ? entry.target : null

  useEffect(() => {
    setViewportHeight(document.documentElement.clientHeight)
  }, [])

  useEffect(() => {
    // console.log("topSentinel", topSentinel)
  }, [topSentinel])

  useEffect(() => {
    // console.log("bottomSentinel", bottomSentinel)
  }, [bottomSentinel])

  useEffect(() => {
    // console.log("percent", percent)
  }, [percent])

  useEffect(() => {
    const handler = () => {
      cancelAnimationFrame(frame.current)

      if (!inView || !entryTarget || !viewportHeight) {
        return
      }

      frame.current = requestAnimationFrame(() => {
        const imageTop = entryTarget.getBoundingClientRect().top
        const imageBottom = entryTarget.getBoundingClientRect().bottom
        const imageHeight = entryTarget.getBoundingClientRect().height

        const mapper = interpolate<number>([0, threshold], [0, 1])

        const part = -1 * imageTop
        const total = imageHeight - viewportHeight
        const percentCompleted = mapper(
          Math.min(Math.max(part / total, 0), 1)
        ) as number

        setPercent(percentCompleted)

        setTopSentinel(imageTop <= 0)
        setBottomSentinel(imageBottom <= viewportHeight)

        if (bg.current) {
          bg.current.style.opacity = (1 - percentCompleted).toFixed(4)
        }
      })
    }

    if (inView) {
      window.addEventListener("scroll", handler, {
        capture: false,
        passive: true,
      })
    }

    return () => {
      cancelAnimationFrame(frame.current)
      window.removeEventListener("scroll", handler)
    }
    // eslint-disable-next-line
  }, [inView, entryTarget, viewportHeight])

  return (
    <Container ref={ref} {...props}>
      <ParallaxBackground
        ref={bg}
        data-in-view={topSentinel && !bottomSentinel}
      >
        <BackgroundImage {...backgroundProps} />
      </ParallaxBackground>
      {children}
    </Container>
  )
}

export default ParallaxOpacity
