import Map from "@components/map"
import OpacityParallax from "@components/parallax-opacity"
import QuickStorySlideshow from "@components/quickstory-slideshow"
import StorySwiper from "@components/story-swiper"
import Video from "@components/video"
import { CenterBlock } from "@ui/Block"
import Box from "@ui/Box"
import Link from "@ui/Link"
import styled from "@emotion/styled"
import { Text, Title } from "@ui/Typography"
import { graphql, Link as GatsbyLink } from "gatsby"
import { FluidObject } from "gatsby-image"
import { shade } from "polished"
import React from "react"

import introMp4 from "../assets/intro.mp4"
import introWebM from "../assets/intro.webm"
import fallbackHeroImage from "../images/fallback_hero.png"
import Layout from "../components/layout"
import SEO from "../components/seo"
import ScrollDown from "@components/ScrollDown"
import ScrollReveal from "@components/ScrollReveal"
import ParallaxSticky from "@components/parallax-sticky"

const AboutButton = styled(GatsbyLink)`
  background-color: ${({ theme }) => theme.colors.jungle};
  width: 100%;
  display: block;
  max-width: 21rem;
  color: #fff;
  text-decoration: none;
  font-family: ${({ theme }) => theme.fonts.effect};
  font-size: ${({ theme }) => theme.fontSizes.lg};
  padding: ${({ theme }) => theme.space.lg};
  text-transform: uppercase;
  text-align: center;
  margin: ${({ theme }) => theme.space["3xl"]} auto 0;

  transition: all 200ms cubic-bezier(0.645, 0.045, 0.355, 1);

  &:focus,
  &:hover {
    outline: 0;
    background-color: ${({ theme }) => shade(0.25, theme.colors.jungle)};
  }
`

const HeroBlock = styled(CenterBlock)`
  position: relative;
  width: 100%;

  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 15rem;
    background: linear-gradient(0deg, transparent, #031542);
  }
`

type Props = {
  data: {
    hero: {
      childImageSharp: {
        fluid: FluidObject
      }
    }
    intro: {
      childImageSharp: {
        fluid: FluidObject
      }
    }
    rahma: {
      childImageSharp: {
        fluid: FluidObject
      }
    }
  }
}

const IndexPage = ({ data }: Props) => {
  return (
    <Layout showFooter>
      <SEO title="Home" />
      <HeroBlock p="0">
        <Video
          bg="#031542"
          loop={false}
          fallbackImageSrc={fallbackHeroImage}
          sources={[
            { src: introWebM, type: "video/webm" },
            { src: introMp4, type: "video/mp4" },
          ]}
        />
        <ScrollDown position="absolute" bottom="3rem" my="auto" href="#intro" />
      </HeroBlock>
      <OpacityParallax
        backgroundProps={{
          fluid: data.hero.childImageSharp.fluid,
        }}
      >
        <CenterBlock bg="white" mt="120vh" id="intro">
          <Box maxWidth="55rem" width="100%">
            <Text
              textAlign="center"
              color="wine"
              fontSize={["xl", "2xl"]}
              lineHeight="tall"
            >
              <strong>
                Entry and Exit Points: Violent Extremism in South-East Asia
              </strong>{" "}
              investigates the factors that contribute towards violent extremism
              and the actions governments can take to prevent it.
            </Text>
            <Text
              textAlign="center"
              color="wine"
              fontSize={["xl", "2xl"]}
              lineHeight="tall"
            >
              In this UNDP-European Union co-funded study, the role of the state
              is examined through empirical research conducted across five
              countries as well as interviews providing personal accounts of
              those impacted by violent extremism.
            </Text>
          </Box>
        </CenterBlock>
      </OpacityParallax>
      <Map />
      <CenterBlock bg="white">
        <ScrollReveal>
          <Box maxWidth="60rem" width="100%">
            <Title
              fontFamily="effect"
              fontSize={{ _: "2xl", sm: "3xl", md: "4xl" }}
              textAlign="center"
              color="midnight"
            >
              “Violent extremism undermines our collective efforts towards
              maintaining peace and security, fostering sustainable development,
              protecting human rights, promoting the rule of law and taking
              humanitarian action.”
            </Title>
            <Text
              textAlign="center"
              color="jungle"
              fontStyle="italic"
              fontWeight={600}
            >
              <Link
                href="https://www.un.org/sg/en/content/sg/statement/2016-04-08/secretary-generals-remarks-geneva-conference-preventing-violent"
                rel="nofollow noreferrer"
                target="_blank"
              >
                The United Nations Secretary General’s Plan of Action to Prevent
                Violent Extremism (2015)
              </Link>
            </Text>
            <Text textAlign="center" fontSize={["lg", "xl"]} lineHeight="tall">
              <strong>
                Exit and Entry Points: Violent Extremism in South-East Asia
              </strong>{" "}
              is UNDP’s contribution to a broader research agenda to support the
              prevention of extremism in South-East Asia
            </Text>
            <AboutButton to="/about">About the research</AboutButton>
          </Box>
        </ScrollReveal>
      </CenterBlock>
      <QuickStorySlideshow />
      <ParallaxSticky>
        <CenterBlock bg="white">
          <Box maxWidth="63rem" width="100%">
            <Title color="sunshade" fontFamily="effect" textAlign="center">
              Stories
            </Title>
            <Text
              color="grey800"
              textAlign="center"
              fontSize={["lg", "xl"]}
              lineHeight="tall"
            >
              As part of this research, UNDP interviewed people who have been
              affected by extremism in different ways. Each of these stories
              presents at best an incomplete picture, but by sharing aspects of
              their raw lived experiences, we can begin to better understand
              what violent extremism means in the context of South-East Asia.
            </Text>
          </Box>
        </CenterBlock>
      </ParallaxSticky>
      <StorySwiper />
    </Layout>
  )
}

export const pageQuery = graphql`
  query {
    hero: file(relativePath: { eq: "rahma.jpg" }) {
      childImageSharp {
        fluid(fit: COVER, maxWidth: 2560, cropFocus: ATTENTION, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    intro: file(relativePath: { eq: "intro_bg.jpg" }) {
      childImageSharp {
        fluid(fit: COVER, maxWidth: 2560, cropFocus: ATTENTION, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    rahma: file(relativePath: { eq: "rahma.jpg" }) {
      childImageSharp {
        fluid(fit: COVER, maxWidth: 2560, cropFocus: ATTENTION, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

export default IndexPage
