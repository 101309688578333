import styled from "@emotion/styled"

import {
  LayoutProps,
  color,
  ColorProps,
  layout,
  space,
  SpaceProps,
  TypographyProps,
  typography,
} from "styled-system"

const Link = styled.a<
  TypographyProps &
    SpaceProps &
    ColorProps &
    LayoutProps & { color?: string; as?: any }
>`
  color: inherit;

  ${space};
  ${layout};
  ${typography};
  ${color};

  transition: opacity 200ms cubic-bezier(0.645, 0.045, 0.355, 1);

  &:hover {
    opacity: 0.7;
  }
`

export default Link
