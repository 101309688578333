import styled from "@emotion/styled"
import React, { useEffect, useRef } from "react"
import {
  color,
  ColorProps,
  layout,
  LayoutProps,
  space,
  SpaceProps,
} from "styled-system"

import { useScrollController } from "./scroll-controller"

const Container = styled.section<
  Omit<ColorProps, "color"> & LayoutProps & SpaceProps
>`
  width: 100%;
  position: relative;
  z-index: 0;
  overflow: hidden;
  color: #fff;
  ${color}
  ${layout}
  ${space}

`

export type ParallaxProps = {
  children?: React.ReactNode
  color?: string
  offset?: number
  duration?: number
} & ColorProps &
  LayoutProps &
  SpaceProps

const ParallaxSticky = ({
  children,
  offset = 0,
  duration = 1000,
  ...props
}: ParallaxProps) => {
  const trigger = useRef<any>()
  const pin = useRef<any>()

  const { ScrollMagic, controller } = useScrollController()

  useEffect(() => {
    if (ScrollMagic && controller) {
      new ScrollMagic.Scene({
        triggerElement: trigger.current,
        offset,
        duration,
        // loglevel: 3,
        triggerHook: "onLeave",
      })
        .setPin(pin.current)
        // .addIndicators()
        .addTo(controller)
    }
  }, [duration, offset, controller, ScrollMagic])

  return (
    <Container {...props} ref={trigger}>
      <div ref={pin}>{children}</div>
    </Container>
  )
}

export default ParallaxSticky
