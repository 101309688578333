import React, { useState } from "react"
import styled from "@emotion/styled"
import { useStaticQuery, graphql } from "gatsby"
import { CenterBlock } from "@ui/Block"
import Box from "@ui/Box"
import GatsbyImage from "gatsby-image"
import { useDrag } from "react-use-gesture"
import AnimateText from "./AnimateText"

const query = graphql`
  query {
    febri: file(relativePath: { eq: "people/febri.jpg" }) {
      childImageSharp {
        fluid(fit: COVER, maxWidth: 1280, cropFocus: ATTENTION, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    ana: file(relativePath: { eq: "people/ana.jpg" }) {
      childImageSharp {
        fluid(fit: COVER, maxWidth: 1280, cropFocus: ATTENTION, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    allen: file(relativePath: { eq: "people/allen.jpg" }) {
      childImageSharp {
        fluid(fit: COVER, maxWidth: 1280, cropFocus: ATTENTION, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

const V_THRESHOLD = 0.4

const QuickStorySlideshow = () => {
  const data = useStaticQuery(query)

  const [slide, setSlide] = useState(0)

  const slides = [
    {
      img: data.febri.childImageSharp.fluid,
      bg: "airdust",
      quote:
        "‘Saya pernah pergi ke Suriah dan tinggal di wilayah ISIS selama kurang lebih satu tahun. Padahal di propaganda bilang bahwa semuanya adil, merata, dapat akses pendidikan dan kesehatan. Tapi, ternyata tidak.’ - Febri ",
      description:
        "“I traveled to Syria and lived in ISIS territory for one year. The propaganda said that it was a just system over there and we would get access to medical and educational services, but it wasn’t like that.” - Febri",
    },
    {
      img: data.ana.childImageSharp.fluid,
      bg: "cyprus",
      quote:
        "“Umalis ang asawa ko bago mag hatinggabi. Mga alas-onse. Sinabi niya may pupuntahan lang siya, pero hindi na siya bumalik. Hindi na rin namin siya makontak mula nang sumabak sya sa bakbakan doon sa Marawi. Akala ko mamamatay na siya doon.”",
      description:
        "“My husband left late at night. It was around 11pm. He just told me he was going somewhere, but then he didn’t come back. We had no contact the whole time he was fighting in Marawi. I thought he was going to die there.” - Ana",
    },
    {
      img: data.allen.childImageSharp.fluid,
      bg: "jungle",
      quote:
        "“Pagod na ako sa pakikipaglaban. Hindi na ako babalik. Ang kontribusyon ko sa Marawi ay sapat na para makapasok ako sa langit. Sana ang mga kasamahan ko na pumanaw na ay nasa paraiso na ngayon.”",
      description:
        "“I won’t fight anymore. I won’t go back. But I believe my contributions in the fight of Marawi will earn me a place in heaven. I hope my brothers who have died are now in paradise” - Allen",
    },
  ]

  const currentSlide = slides[slide] || slides[0]

  const paginate = (newDirection: number) => {
    setSlide(page => (page + newDirection + slides.length) % slides.length)
  }

  const bind = useDrag(({ last, vxvy: [vx, vy] }) => {
    if (last) {
      // swipe left is when horizontal velocity is inferior to minus threshold
      if (vx < -V_THRESHOLD) {
        paginate(1)
      }
      // swipe right is when horizontal velocity is superior to threshold
      else if (vx > V_THRESHOLD) {
        paginate(-1)
      }
    }
  })

  return (
    <Background bg={currentSlide.bg} {...bind()}>
      <Box maxWidth="60rem">
        <Box>
          <Grid>
            <Image
              key={currentSlide.bg}
              fluid={currentSlide.img}
              alt=""
              loading="eager"
            />
            <Quote>
              <AnimateText
                start={currentSlide.quote}
                end={currentSlide.description}
              />
            </Quote>
          </Grid>
          <IndicatorNav>
            {slides.map((_, index) => (
              <Indicator
                key={index}
                aria-label={`Slide ${index + 1}`}
                aria-selected={index === slide}
                onClick={() => setSlide(index)}
              />
            ))}
          </IndicatorNav>
        </Box>
      </Box>
    </Background>
  )
}

const Background = styled(CenterBlock)`
  transition: all 300ms cubic-bezier(0.645, 0.045, 0.355, 1);
`

const Grid = styled.div`
  position: relative;
  display: grid;

  justify-items: center;

  @media screen and (min-width: 70rem) {
    justify-items: flex-start;
    grid-template-columns: repeat(4, 1fr);
  }
`

const Image = styled(GatsbyImage)`
  width: 100%;
  max-width: 40rem;

  @media screen and (min-width: 70rem) {
    grid-column: 1 / span 3;
    grid-row: 1;

    &:after {
      position: absolute;
      right: 0;
      top: 0;
      height: 100%;
      width: 80%;
      background: linear-gradient(90deg, transparent, rgba(0, 0, 0, 0.6));
      display: block;
      content: "";
    }
  }
`

const Quote = styled.blockquote`
  position: relative;
  font-family: ${({ theme }) => theme.fonts.effect};
  font-size: ${({ theme }) => theme.fontSizes["2xl"]};
  line-height: ${({ theme }) => theme.lineHeights.shorter};
  color: ${({ theme }) => theme.colors.wheat};
  text-transform: uppercase;
  margin: 0;
  margin-top: 2rem;

  width: 30ch;

  text-shadow: 1px 2px 6px rgba(0, 0, 0, 0.25), 1px 0px 2px rgba(0, 0, 0, 0.3);

  min-height: 16rem;

  @media screen and (min-width: 50rem) {
    font-size: ${({ theme }) => theme.fontSizes["3xl"]};
  }

  @media screen and (min-width: 70rem) {
    margin: 2rem;
    grid-row: 1;
    grid-column: -3 / -1;
  }
`

const IndicatorNav = styled.div`
  justify-content: center;
  align-items: flex-end;
  display: flex;
  margin-top: 2rem;
`

const Indicator = styled.button`
  height: 6px;
  width: 96px;
  background-color: #fff;
  border: 0;
  appearance: none;
  display: inline-block;
  vertical-align: bottom;
  margin: 12px 0;

  opacity: 0.25;

  transition: all 200ms cubic-bezier(0.645, 0.045, 0.355, 1);

  cursor: pointer;
  position: relative;

  &:after {
    content: "";
    position: absolute;
    top: -12px;
    left: 0;
    right: 0;
    bottom: -12px;
  }

  &:hover,
  &:focus,
  &[aria-selected="true"] {
    outline: 0;
    opacity: 1;
    height: 12px;
  }

  &[aria-selected="true"] {
    background-color: ${({ theme }) => theme.colors.coral};
  }

  & + & {
    margin-left: 12px;
  }
`

export default QuickStorySlideshow
