import React, { useState, useRef, useEffect } from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import { wrap } from "@popmotion/popcorn"
import { tween, easing } from "popmotion"
import { motion, AnimatePresence, Variants } from "framer-motion"
import TopIcon from "../images/chevron-top.inline.svg"
import BottomIcon from "../images/chevron-bottom.inline.svg"
import styled from "@emotion/styled"
import { useDrag } from "react-use-gesture"
import * as THREE from "three"

const vertex = `
    varying vec2 vUv;
    void main() {
      vUv = uv;
      gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
    }
`

const fragment = `
    varying vec2 vUv;

    uniform sampler2D texture;
    uniform sampler2D texture2;
    uniform sampler2D disp;

    // uniform float time;
    // uniform float _rot;
    uniform float dispFactor;
    uniform float effectFactor;

    // vec2 rotate(vec2 v, float a) {
    //  float s = sin(a);
    //  float c = cos(a);
    //  mat2 m = mat2(c, -s, s, c);
    //  return m * v;
    // }

    void main() {

        vec2 uv = vUv;

        // uv -= 0.5;
        // vec2 rotUV = rotate(uv, _rot);
        // uv += 0.5;

        vec4 disp = texture2D(disp, uv);

        vec2 distortedPosition = vec2(uv.x + dispFactor * (disp.r*effectFactor), uv.y);
        vec2 distortedPosition2 = vec2(uv.x - (1.0 - dispFactor) * (disp.r*effectFactor), uv.y);

        vec4 _texture = texture2D(texture, distortedPosition);
        vec4 _texture2 = texture2D(texture2, distortedPosition2);

        vec4 finalTexture = mix(_texture, _texture2, dispFactor);

        gl_FragColor = finalTexture;
        // gl_FragColor = disp;
    }
`

const Container = styled.div`
  width: 100%;
  height: 100vh;
  position: relative;
  background-color: #333;
  overflow: hidden;
`

const ImageItem = styled.div`
  display: block;
  height: 100%;
  width: 100%;

  canvas {
    display: block;
    height: 100% !important;
    width: 100% !important;
    object-fit: cover;
  }
`

const TextBlock = styled(motion.div)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 2rem;
`

const Title = styled.h2`
  font-family: ${({ theme }) => theme.fonts.effect};
  color: ${({ theme }) => theme.colors.wheat};
  text-transform: uppercase;
  letter-spacing: 0.025em;
  font-size: 3.5rem;
  margin: 0;
  font-weight: 400;
  line-height: 1.1;

  width: 100%;
  text-align: center;

  @media all and (min-width: 40em) {
    font-size: 4.5rem;
  }

  @media all and (min-width: 70em) {
    font-size: 9.5rem;
  }
`

const Description = styled.p`
  color: ${({ theme }) => theme.colors.wheat};
  font-style: italic;
  margin: 0;
  line-height: 1.25;
  font-size: 1.25rem;
  max-width: 25rem;
  text-align: center;

  @media all and (min-width: 40em) {
    font-size: 1.75rem;
    max-width: 40rem;
  }

  @media all and (min-width: 70em) {
    font-size: 2.5rem;
    max-width: 50rem;
  }
`

const ReadMore = styled(Link)`
  font-family: ${({ theme }) => theme.fonts.effect};
  color: ${({ theme }) => theme.colors.wheat};
  display: block;
  text-decoration: none;
  text-transform: uppercase;
  border-bottom: 1px solid currentColor;
  margin-top: 6rem;
  transition: opacity 200ms cubic-bezier(0.645, 0.045, 0.355, 1);

  &:focus,
  &:hover {
    outline: 0;
    opacity: 0.6;
  }
`

const PageIndicator = styled.span`
  color: #fff;
  font-family: ${({ theme }) => theme.fonts.effect};
  text-transform: uppercase;
  letter-spacing: 0.05em;
  white-space: nowrap;
`

const Controls = styled.div`
  position: absolute;
  z-index: 1;
  bottom: 0;
  right: 50%;
  transform: translateX(50%);
  padding: 2rem;

  display: flex;
  align-items: center;
  flex-direction: row;

  @media screen and (min-width: 55rem) {
    bottom: 50%;
    transform: translateY(50%);
    right: 0;
    flex-direction: column;
  }
`

const NavArrow = styled.button`
  background: transparent;
  border: 0;
  appearance: none;
  padding: 2rem;
  opacity: 0.7;
  transition: opacity 200ms cubic-bezier(0.645, 0.045, 0.355, 1);
  cursor: pointer;

  :focus {
    outline: 0;
  }

  :hover,
  :focus {
    opacity: 1;
  }

  svg {
    stroke: #fff;
  }

  @media screen and (max-width: 55rem) {
    &[data-prev] {
      transform: rotate(-90deg);
    }
    &[data-next] {
      transform: rotate(-90deg);
    }
  }
`

const variants: Variants = {
  enter: {
    y: "20%",
    opacity: 0,
  },
  center: {
    zIndex: 1,
    y: "0%",
    opacity: 1,
  },
  exit: {
    position: "absolute",
    zIndex: 0,
    y: "20%",
    opacity: 0,
  },
}

const query = graphql`
  fragment SwiperImage on File {
    childImageSharp {
      fluid(fit: COVER, maxWidth: 2560, cropFocus: ATTENTION, quality: 80) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }

  query {
    rahma: file(relativePath: { eq: "rahma.jpg" }) {
      ...SwiperImage
    }
    amir: file(relativePath: { eq: "amir.jpg" }) {
      ...SwiperImage
    }
    ahmad: file(relativePath: { eq: "ahmad.jpg" }) {
      ...SwiperImage
    }
    odin: file(relativePath: { eq: "odin.jpg" }) {
      ...SwiperImage
    }
    khairul: file(relativePath: { eq: "khairul.jpg" }) {
      ...SwiperImage
    }

    displacementMap: file(relativePath: { eq: "displacement_map.jpg" }) {
      childImageSharp {
        fixed {
          src
        }
      }
    }
  }
`

const StorySwiper = () => {
  const data = useStaticQuery(query)
  const ref = useRef<HTMLDivElement>(null)
  const c = useRef<any>(null)
  const t = useRef<any>(null)

  const [[page, direction], setPage] = useState([0, 0])
  const [isAnimating, setAnimating] = useState<boolean>(false)

  const images = [
    {
      img: data.odin.childImageSharp.fluid,
      link: "/story/odin",
      title: "Odin's story",
      description:
        "‘When disaster strikes, our crops die and with it our livelihood. ISIS offered us money, and we joined them with the belief that our lives would become comfortable.’",
    },
    {
      img: data.rahma.childImageSharp.fluid,
      link: "/story/rahma",
      title: "Rahma's story",
      description: "‘…we were at the lowest point of our lives.’",
    },
    {
      img: data.ahmad.childImageSharp.fluid,
      link: "/story/ahmad",
      title: "Ahmad's story",
      description:
        "‘When I think back, I am scared about what would have happened if I had gone too far down the rabbit hole…’",
    },
    {
      img: data.khairul.childImageSharp.fluid,
      link: "/story/khairul",
      title: "Khairul's story",
      description:
        "‘The children are victims in this. They pay the price for their parent’s choices.’",
    },
    {
      img: data.amir.childImageSharp.fluid,
      link: "/story/amir",
      title: "Amir's story",
      description:
        "‘I feel like we can prevent terrorism by consulting more ex-terrorists. We have valuable experiences and knowledge to share.’",
    },
  ]

  const currImageIndex = wrap(0, images.length, page)
  const prevImageIndex = wrap(0, images.length, page - direction)

  const paginate = (newDirection: number) => {
    setPage([page + newDirection, newDirection])
  }

  const intensity = -0.65

  useEffect(() => {
    const el = ref.current

    if (!el) return

    const sceneWidth = el.offsetWidth
    const sceneHeight = (sceneWidth * 9) / 16 // 16:9 ratio

    const scene = new THREE.Scene()
    const camera = new THREE.OrthographicCamera(
      sceneWidth / -2,
      sceneWidth / 2,
      sceneHeight / 2,
      sceneHeight / -2,
      1,
      1000
    )

    camera.position.z = 1

    const renderer = new THREE.WebGLRenderer({
      antialias: false,
    })

    renderer.setPixelRatio(window.devicePixelRatio)
    renderer.setClearColor(0xffffff, 0.0)
    renderer.setSize(sceneWidth, sceneHeight)
    el.appendChild(renderer.domElement)

    const loader = new THREE.TextureLoader()
    loader.crossOrigin = ""

    const textures = images.map(image => {
      const texture = loader.load(image.img.src)

      texture.magFilter = THREE.LinearFilter
      texture.minFilter = THREE.LinearFilter
      texture.anisotropy = renderer.capabilities.getMaxAnisotropy()

      return texture
    })

    t.current = textures

    const disp = loader.load(data.displacementMap.childImageSharp.fixed.src)
    disp.wrapS = disp.wrapT = THREE.RepeatWrapping

    const material = new THREE.ShaderMaterial({
      uniforms: {
        effectFactor: { type: "f", value: intensity },
        dispFactor: { type: "f", value: 0.0 },
        texture: { type: "t", value: textures[0] },
        texture2: { type: "t", value: textures[1] },
        disp: { type: "t", value: disp },
      },

      vertexShader: vertex,
      fragmentShader: fragment,
      transparent: true,
      opacity: 1.0,
    })

    c.current = material

    const geometry = new THREE.PlaneBufferGeometry(sceneWidth, sceneHeight, 1)
    const object = new THREE.Mesh(geometry, material)
    scene.add(object)

    let frameId: number

    const animate = () => {
      renderer.render(scene, camera)
      frameId = requestAnimationFrame(animate)
    }

    animate()

    return () => {
      cancelAnimationFrame(frameId)
      el.removeChild(renderer.domElement)
      scene.remove(object)
      geometry.dispose()
      material.dispose()
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (!t.current || !c.current || currImageIndex === prevImageIndex) return

    if (direction > 0) {
      c.current.uniforms.texture.value = t.current[prevImageIndex]
      c.current.uniforms.texture2.value = t.current[currImageIndex]
      c.current.uniforms.dispFactor.value = 0
    } else {
      c.current.uniforms.texture.value = t.current[currImageIndex]
      c.current.uniforms.texture2.value = t.current[prevImageIndex]
      c.current.uniforms.dispFactor.value = 1
    }

    setAnimating(true)
    requestAnimationFrame(() => {
      tween({
        from: direction > 0 ? 0 : 1,
        to: direction > 0 ? 1 : 0,
        duration: 600,
        ease: easing.easeOut,
      }).start({
        update: (v: any) => {
          c.current.uniforms.dispFactor.value = v
        },
        complete: () => {
          setAnimating(false)
        },
      })
    })
  }, [currImageIndex, direction, prevImageIndex])

  const V_THRESHOLD = 0.4

  const bind = useDrag(({ last, vxvy: [vx, vy] }) => {
    if (last) {
      // swipe left is when horizontal velocity is inferior to minus threshold
      if (vx < -V_THRESHOLD) {
        paginate(1)
      }
      // swipe right is when horizontal velocity is superior to threshold
      else if (vx > V_THRESHOLD) {
        paginate(-1)
      }
    }
  })

  const current = images[currImageIndex]

  return (
    <Container {...bind()}>
      <ImageItem ref={ref} />
      <AnimatePresence initial={false} custom={direction}>
        <TextBlock
          key={currImageIndex}
          custom={direction}
          variants={variants}
          initial="enter"
          animate="center"
          exit="exit"
          transition={{
            y: { type: "spring", stiffness: 30, damping: 10 },
            opacity: { duration: 0.4 },
          }}
        >
          <Title>{current.title}</Title>
          <Description>{current.description}</Description>
          <ReadMore to={current.link}>Read more</ReadMore>
        </TextBlock>
      </AnimatePresence>
      <Controls>
        <NavArrow data-prev disabled={isAnimating} onClick={() => paginate(-1)}>
          <TopIcon />
        </NavArrow>
        <PageIndicator>
          Story {currImageIndex + 1} / {images.length}
        </PageIndicator>
        <NavArrow data-next disabled={isAnimating} onClick={() => paginate(1)}>
          <BottomIcon />
        </NavArrow>
      </Controls>
    </Container>
  )
}

export default StorySwiper
