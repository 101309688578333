import React, { useEffect, useState, useRef } from "react"
import styled from "@emotion/styled"

const Remaining = styled.span`
  transition: opacity 200ms cubic-bezier(0.645, 0.045, 0.355, 1);
  opacity: 1;

  &[data-animating="true"] {
    opacity: 0.4;
  }
`

export type AnimateTextProps = {
  delay?: number
  start: string
  end: string
  speed?: number
  onComplete?: () => void
}

const AnimateText = ({
  delay = 4000,
  start,
  end,
  speed = 40,
  onComplete = () => {},
}: AnimateTextProps) => {
  const maxLength = Math.max(start.length, end.length)

  const first = start.padEnd(maxLength, " ")
  const second = end.padEnd(maxLength, " ")

  const [state, setState] = useState({
    index: 0,
    characters: first,
    savedChars: first,
    remaining: "",
  })

  const [isStarted, setStarted] = useState(false)

  const ref = useRef<any | null>(null)

  useEffect(() => {
    setState({
      index: 0,
      characters: first,
      savedChars: first,
      remaining: "",
    })
  }, [first, second])

  useEffect(() => {
    if (delay <= 0) {
      setStarted(true)
      return
    }
    const timeoutId = setTimeout(() => {
      setStarted(true)
    }, delay)
    return () => {
      setStarted(false)
      if (timeoutId) {
        clearTimeout(timeoutId)
      }
    }
  }, [delay, first, second])

  // don't randomize. just replace
  useEffect(() => {
    if (!isStarted) {
      return
    }
    ref.current = setInterval(() => {
      setState(prev => {
        const index = prev.index + 1
        const savedChars = second.slice(0, index)
        const remaining = first.slice(index)
        return {
          index,
          savedChars,
          remaining,
          characters: savedChars + remaining,
        }
      })
    }, speed)
    return () => {
      clearInterval(ref.current)
    }
  }, [first, isStarted, second, speed])

  useEffect(() => {
    if (state.index === maxLength && ref.current) {
      onComplete()
      clearInterval(ref.current)
    }
  }, [maxLength, onComplete, state.index])

  return (
    <>
      <span>{state.savedChars}</span>
      <Remaining data-animating={isStarted}>{state.remaining}</Remaining>
    </>
  )
}

export default AnimateText
